import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';
import PrivateRoute from './Helpers/PrivateRoute';
import AdminRoute from './Helpers/AdminRoute';

// Import pages
import Login from './pages/Login';
// import Dashboard from './pages/admin/Dashboard';
import Companies from './pages/admin/Companies';
import Departments from './pages/admin/Departments';
import Users from './pages/admin/Users';
import UserAttendance from './pages/admin/UserAttendance';
import TodayAttendance from './pages/admin/TodayAttendance';
import NewCompany from './pages/admin/NewCompany';
import NewDepartment from './pages/admin/NewDepartment';
import NewUser from './pages/admin/NewUser';
import EditUser from './pages/admin/EditUser';
import ChangePassword from './pages/admin/ChangePassword';
import EditCompany from './pages/admin/EditCompany';

import ManagerRoute from './Helpers/ManagerRoute';

import ManagerDashboard from './pages/manager/Dashboard';
import ManagerUsers from './pages/manager/Users';
import ManagerNewUser from './pages/manager/NewUser';
import ManagerEditUser from './pages/manager/EditUser';

import HRRoute from './Helpers/HRRoute';
import HRDashboard from './pages/hr/Dashboard';
import HRUsers from './pages/hr/Users';

import EmployeeRoute from './Helpers/EmployeeRoute';
import EmployeeDashboard from './pages/employee/Dashboard';
import ShiftScreenshots from './pages/admin/ShiftScreenshots';
import AllNotifications from './pages/admin/Notifications';
import LImage from './pages/Image';

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/image" component={LImage} />
        {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
        <AdminRoute path="/companies" component={Companies} />
        <AdminRoute path="/edit-company/:id" component={EditCompany} />
        <AdminRoute path="/new-company" component={NewCompany} />
        <AdminRoute path="/departments" component={Departments} />
        <AdminRoute path="/new-department" component={NewDepartment} />
        <PrivateRoute path="/users/:status" component={Users} />
        <PrivateRoute path="/new-user" component={NewUser} />
        <PrivateRoute path="/edit-user/:id" component={EditUser} />
        <PrivateRoute path="/user/history/:id" component={UserAttendance} />
        <PrivateRoute path="/user/screenshots/:user/:date" component={ShiftScreenshots} />
        <PrivateRoute path="/change-password" component={ChangePassword} />

        <PrivateRoute path="/notifications" component={AllNotifications} />
        <PrivateRoute path="/users-attendance" component={TodayAttendance} />

        {/* <ManagerRoute path="/manager/dashboard" component={ManagerDashboard} /> */}
        <ManagerRoute path="/manager/new-user" component={ManagerNewUser} />
        <ManagerRoute path="/manager/users/:status" component={ManagerUsers} />
        <ManagerRoute path="/manager/edit-user/:id" component={ManagerEditUser} />
        
        {/* <HRRoute path="/hr/dashboard" component={HRDashboard} /> */}
        <HRRoute path="/hr/users/:status" component={HRUsers} />

        {/* <EmployeeRoute path="/employee/dashboard" component={EmployeeDashboard} /> */}
        {/* <EmployeeRoute path="/employee/users" component={EmployeeUsers} /> */}
      </Switch>
    </>
  );
}

export default App;
