import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsTrashFill, BsPencilSquare } from 'react-icons/bs';
import "../../css/companies.scss";
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

function Companies(props) {
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
        axios
            .get(process.env.REACT_APP_API_URL + "companies", {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    setCompanies(response.data.result);
                    setLoading(false);
                }
            })
            .catch((error) => {
                showError(error.message);
                setLoading(false);
            });
    }, []);

    const onTrashIconClicked = (company) => {
        setLoading(true);
        axios
            .get(process.env.REACT_APP_API_URL + "company/delete/" + company.id, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    if (response.data.status == 200) {
                        showSuccess(response.data.message);
                        setCompanies(companies.filter(c => c.id != company.id))
                    }
                    else {
                        showError(response.data.message);
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                showError(error.message);
                setLoading(false);
            })
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 companies">
            <header className="px-5 py-4 border-b border-gray-100" style={{ display: "flex" }}>
                <h2 className="font-semibold text-gray-800">Companies</h2>
                <Link className="new-company-btn" to="/new-company">New Company</Link>
            </header>
            <div className="px-5 py-4 border-b border-gray-100">
                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                        {/* Table header */}
                        <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">ID</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Logo</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Name</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="text-sm divide-y divide-gray-100">
                            {
                                loading ?
                                <tr>
                                    <td className="loader-div text-center" colSpan="4">
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                        />
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                        />
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                                :
                                companies.map(company => {
                                    return (
                                        <tr key={company.id}>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="font-medium text-gray-800">{company.id}</div>
                                                </div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <img className="rounded-full" src={process.env.REACT_APP_API_PUBLIC_URL + "storage/companies/" + company.id + "/" + company.logo} width="40" height="40" alt={company.name} />
                                                </div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="font-medium text-gray-800">{company.name}</div>
                                            </td>
                                            <td className="p-2 whitespace-nowrap">
                                                <div className="text-left font-medium text-gray-500" style={{ display: "flex" }}>
                                                    <Link to={`/edit-company/` + company.id}>
                                                        <BsPencilSquare className="edit-icon" />
                                                    </Link>
                                                    <BsTrashFill className="trash-icon" onClick={() => onTrashIconClicked(company)} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Companies;
