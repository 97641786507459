import React, { useEffect, useState } from 'react';
import { BiCommentAdd, BiCommentDetail, BiCamera } from "react-icons/bi";
import moment from 'moment';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Leave from '../../images/leave.png';
import "../../css/users.scss";

function UserAttendance(props) {
    const [loading, setLoading] = useState(true);
    const [breaks_loading, setBreaksLoading] = useState(true);
    const [user, setUser] = useState({});
    const [attendance, setAttendance] = useState([]);
    const [breaks, setBreaks] = useState([]);
    const [full_time_days, setFullTimeDays] = useState();
    const [low_working_hours_days, setLowWorkingHoursDays] = useState();
    const [absents, setAbsents] = useState([]);
    const [breaksOfDay, setBreaksOfDay] = useState([]);
    const [breaksModalShow, setBreaksModalShow] = React.useState(false);
    const [month, setMonth] = useState((new Date().getMonth() + 1).toString().length == 1 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1));
    const [year, setYear] = useState(new Date().getFullYear());
    const [weekday] = useState([
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]);

    const [shift_reason_modal_show, setShiftReasonModalShow] = useState("");
    const [opened_attendance, setOpenedAttendance] = useState();
    const [shift_reason, setShiftReason] = useState("");
    const [shift_reason_error, setShiftReasonError] = useState("");
    const [break_reason_modal_show, setBreakReasonModalShow] = useState("");
    const [opened_break, setOpenedBreak] = useState();
    const [break_reason, setBreakReason] = useState("");
    const [break_reason_error, setBreakReasonError] = useState("");
    const [total_break_time, setTotalBreakTime] = useState("00:00:00");

    const [leave_reason_modal_show, setLeaveReasonModalShow] = useState("");
    const [leave_reason, setLeaveReason] = useState("");
    const [leave_reason_error, setLeaveReasonError] = useState("");

    function dateFormat(str) {
        var arr = str.split("-");
        arr = arr.reverse();

        return arr[0] + "-" + arr[1] + "-" + arr[2];
    }

    useEffect(() => {
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
    }, []);

    useEffect(() => {
        if (
            JSON.parse(localStorage.getItem("user")).role == "admin" ||
            JSON.parse(localStorage.getItem("user")).role == "manager" ||
            JSON.parse(localStorage.getItem("user")).department.name == "HR Department" ||
            (JSON.parse(localStorage.getItem("user")).role == "employee" && JSON.parse(localStorage.getItem("user")).id == props.props.match.params.id)
        ) {
            axios
                .get(process.env.REACT_APP_API_URL + "user/" + props.props.match.params.id + "/attendance/" + month + "/" + year, {
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        setAttendance(response.data.result.attendance);
                        setBreaks(response.data.result.breaks);
                        setLowWorkingHoursDays(response.data.result.low_working_hours_days);
                        setAbsents(response.data.result.absents);
                        setUser(response.data.result.user);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    showError(error.response.data.message);
                    setLoading(false);
                });
        }
        else {
            showError("Access Denied!");
        }
        if (localStorage.getItem("opened_break")) {
            var break_id = localStorage.getItem("opened_break");
            localStorage.removeItem("opened_break");

            axios
                .get(process.env.REACT_APP_API_URL + "break/" + break_id, {
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    setBreaksModalShow(false);
                    setBreakReasonModalShow(true);
                    setOpenedBreak(response.data.result);
                    setBreakReason(response.data.result.reason);
                })
        }
    }, [props.props.match.params.id, localStorage.getItem("opened_break")]);

    const onInputFieldChanged = (e) => {
        if (e.target.name == "month") {
            setMonth(e.target.value);
        }
        if (e.target.name == "year") {
            setYear(e.target.value);
        }
    }

    const onViewBreakClicked = (atndnc) => {
        setBreaksLoading(true);
        axios
            .get(process.env.REACT_APP_API_URL + "shift/breaks/" + props.props.match.params.id + "/" + atndnc.date, {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                var total_break = "00:00:00";
                if (response.data) {
                    setBreaksOfDay(response.data.result);
                    response.data.result.map((b) => {
                        var break_difference = moment.utc(moment(b.end_at, "HH:mm:ss").diff(moment(b.start_at, "HH:mm:ss"))).format("HH:mm:ss");

                        var hms = break_difference.toString();   // your input string
                        var a = hms.split(':'); // split it at the colons

                        // minutes are worth 60 seconds. Hours are worth 60 minutes.
                        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        total_break = moment(total_break, "HH:mm:ss").add(seconds, 'seconds');
                    });
                    setTotalBreakTime(moment(new Date(total_break)).format("HH:mm:ss"));
                }
                setBreaksLoading(false);
            })
            .catch((error) => {
                showError(error.response.data.message);
                setBreaksLoading(false);
            });
        setBreaksModalShow(true);
    }

    const onSearchBtnClicked = () => {
        setLoading(true);
        axios
            .get(process.env.REACT_APP_API_URL + "user/" + props.props.match.params.id + "/attendance/" + month + "/" + year, {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    setAttendance(response.data.result.attendance);
                    setBreaks(response.data.result.breaks);
                    setLowWorkingHoursDays(response.data.result.low_working_hours_days);
                    setAbsents(response.data.result.absents);
                    setUser(response.data.result.user);
                }
                setLoading(false);
            })
            .catch((error) => {
                showError(error.response.data.message);
                setLoading(false);
            });
    }

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Breaks
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                {/* Table header */}
                                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Sr No.</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Date</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Time in</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Time out</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Break Time<br />(HH:mm:ss)</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">ACTION</div>
                                        </th>
                                    </tr>
                                </thead>
                                {/* Table body */}
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {
                                        breaks_loading ?
                                            <tr>
                                                <td className="loader-div text-center" colSpan="6">
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                    />
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                    />
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            breaksOfDay.map((b, i) => {
                                                return (
                                                    <tr key={b.id}
                                                        style={{
                                                            backgroundColor:
                                                                b.is_approved == 1 ?
                                                                    "lightgreen"
                                                                    :
                                                                    b.is_rejected == 1 ?
                                                                        "red"
                                                                        :
                                                                        b.request_for_approval == 1 ?
                                                                            "yellow"
                                                                            :
                                                                            ""
                                                        }}>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="font-medium text-gray-800">{i + 1}</div>
                                                            </div>
                                                        </td>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="font-medium text-gray-800">{b.date}</div>
                                                        </td>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="font-medium text-gray-800">{b.start_at.split(".")[0]}</div>
                                                        </td>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="font-medium text-gray-800">{b.end_at.split(".")[0]}</div>
                                                        </td>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="font-medium text-gray-800">
                                                                {moment.utc(moment(b.end_at, "HH:mm:ss").diff(moment(b.start_at, "HH:mm:ss"))).format("HH:mm:ss")}
                                                            </div>
                                                        </td>
                                                        <td className="p-2 whitespace-nowrap">
                                                            <div className="font-medium text-gray-800">
                                                                {
                                                                    b.reason ?
                                                                        <span style={{ cursor: "pointer" }} onClick={() => onBreakAddReasonClicked(b)}>
                                                                            <BiCommentDetail style={{ fontSize: "20px" }} />
                                                                        </span>
                                                                        :
                                                                        JSON.parse(localStorage.getItem("user")).role == "employee" || JSON.parse(localStorage.getItem("user")).role == "manager" ?
                                                                            JSON.parse(localStorage.getItem("user")).id == b?.u_id ?
                                                                                <span style={{ cursor: "pointer" }} onClick={() => onBreakAddReasonClicked(b)}>
                                                                                    <BiCommentAdd style={{ fontSize: "20px" }} />
                                                                                </span>
                                                                                :
                                                                                <></>
                                                                            :
                                                                            <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <hr />
                        <table className="table-auto w-full mx-auto" style={{ maxWidth: "500px" }}>
                            <tbody className="text-xs font-semibold uppercase">
                                <tr>
                                    <th className="p-2 whitespace-nowrap bg-gray-50">
                                        <div className="font-semibold text-left">Total Break Time:</div>
                                    </th>
                                    <td className="p-2 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className="font-medium text-gray-800"><b>{total_break_time}&nbsp;(hh:mm:ss)</b></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const onShiftAddReasonClicked = (atndnce) => {
        setShiftReasonModalShow(true);
        setOpenedAttendance(atndnce);
        setShiftReason(atndnce?.attendance?.reason);
    }

    const onApplyLeaveIconClicked = (atndnce) => {
        setLeaveReasonModalShow(true);
        setOpenedAttendance(atndnce);
        setLeaveReason(atndnce?.attendance?.leave_reason);
    }

    const onShiftInputFieldChanged = (e) => {
        if (e.target.name == "shift_reason") {
            setShiftReason(e.target.value);
        }
    }

    const onLeaveInputReasonChanged = (e) => {
        if (e.target.name == "leave_reason") {
            setLeaveReason(e.target.value);
        }
    }

    const onBreakAddReasonClicked = (brk) => {
        setBreaksModalShow(false);
        setBreakReasonModalShow(true);
        setOpenedBreak(brk);
        setBreakReason(brk?.reason);
    }

    const onBreakInputFieldChanged = (e) => {
        if (e.target.name == "break_reason") {
            setBreakReason(e.target.value);
        }
    }

    function shiftReasonValidation() {
        var formIsValid = true;

        if (!shift_reason) {
            setShiftReasonError("*Reason is required.");
            formIsValid = false;
        }
        else {
            setShiftReasonError("");
        }

        return formIsValid;
    }

    function breakReasonValidation() {
        var formIsValid = true;

        if (!break_reason) {
            setBreakReasonError("*Reason is required.");
            formIsValid = false;
        }
        else {
            setBreakReasonError("");
        }

        return formIsValid;
    }

    function leaveReasonValidation() {
        var formIsValid = true;

        if (!leave_reason) {
            setLeaveReasonError("*Reason is required.");
            formIsValid = false;
        }
        else {
            setLeaveReasonError("");
        }

        return formIsValid;
    }

    const onSubmitShiftReasonClicked = () => {
        if (shiftReasonValidation()) {
            let id;
            if (opened_attendance.attendance) {
                id = opened_attendance.attendance.id;
            }
            else {
                id = null;
            }

            axios
                .post(process.env.REACT_APP_API_URL + "attendance/shift-reason/" + id, {
                    reason: shift_reason,
                    date: opened_attendance.date,
                    user: props.props.match.params.id
                }, {
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        setShiftReason("");
                        setShiftReasonModalShow(false);
                        var prev_attendance = attendance;
                        prev_attendance.forEach(at => {
                            if (at.date == opened_attendance.date) {
                                var attndnce = response.data.result;
                                at.attendance = attndnce;
                            }
                        });
                        setAttendance(prev_attendance);
                        showSuccess(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                });
        }
    }

    const onSubmitLeaveReasonClicked = () => {
        if (leaveReasonValidation()) {
            let id;
            if (opened_attendance.attendance) {
                id = opened_attendance.attendance.id;
            }
            else {
                id = null;
            }

            axios
                .post(process.env.REACT_APP_API_URL + "attendance/apply-leave/" + id, {
                    reason: leave_reason,
                    date: opened_attendance.date,
                    user: props.props.match.params.id
                }, {
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        setLeaveReason("");
                        setLeaveReasonModalShow(false);
                        var prev_attendance = attendance;
                        prev_attendance.forEach(at => {
                            if (at.date == opened_attendance.date) {
                                var attndnce = response.data.result;
                                at.attendance = attndnce;
                            }
                        });
                        setAttendance(prev_attendance);
                        showSuccess(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                });
        }
    }

    const onSubmitBreakReasonClicked = () => {
        if (breakReasonValidation()) {
            let id;
            if (opened_break) {
                id = opened_break.id;
            }
            else {
                id = null;
            }

            axios
                .post(process.env.REACT_APP_API_URL + "attendance/break-reason/" + id, {
                    reason: break_reason,
                    request_for_approval: 0
                }, {
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        setBreakReason("");
                        setBreakReasonModalShow(false);
                        var prev_breaks = breaks;
                        prev_breaks.map((brk) => {
                            if (brk.id == opened_break.id) {
                                brk.reason = response.data.result.reason;
                            }
                        });
                        setBreaks(prev_breaks);
                        showSuccess(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                });
        }
    }

    const onSubmitAndRequestForApprovalBreakReasonClicked = () => {
        if (breakReasonValidation()) {
            let id;
            if (opened_break) {
                id = opened_break.id;
            }
            else {
                id = null;
            }

            axios
                .post(process.env.REACT_APP_API_URL + "attendance/break-reason/" + id, {
                    reason: break_reason,
                    request_for_approval: 1
                }, {
                    headers: {
                        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        setBreakReason("");
                        setBreakReasonModalShow(false);
                        var prev_breaks = breaks;
                        prev_breaks.map((brk) => {
                            if (brk.id == opened_break.id) {
                                brk.reason = response.data.result.reason;
                            }
                        })
                        setBreaks(prev_breaks);
                        showSuccess(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                });
        }
    }

    const onApproveBreakBtnClicked = () => {
        var url = "";
        if (JSON.parse(localStorage.getItem("user")).role == "admin") {
            url = process.env.REACT_APP_API_URL + "attendance/approve-break/" + opened_break?.id;
        }
        else if (JSON.parse(localStorage.getItem("user")).role == "manager") {
            url = process.env.REACT_APP_API_URL + "manager/attendance/approve-break/" + opened_break?.id;
        }
        else if (JSON.parse(localStorage.getItem("user"))?.department?.name == "HR Department") {
            url = process.env.REACT_APP_API_URL + "hr/attendance/approve-break/" + opened_break?.id;
        }

        axios
            .get(url, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    if (response.data.status == 200) {
                        var prev_attendance = attendance;
                        prev_attendance.map((att) => {
                            if (att.date == response.data.result.attendance.date) {
                                att.attendance.working_hours = response.data.result.attendance.working_hours;
                            }
                        });
                        setAttendance(prev_attendance);
                        var prev_breaks = breaks;
                        prev_breaks.map((brk) => {
                            if (brk.id == opened_break.id) {
                                brk.is_approved = response.data.result.break.is_approved;
                                brk.is_rejected = response.data.result.break.is_rejected;
                            }
                        });
                        setBreaks(prev_breaks);
                        setBreakReasonModalShow(false);
                        showSuccess(response.data.message);
                    }
                    else {
                        setBreakReasonModalShow(false);
                        showError(response.data.message);
                    }
                }
            })
            .catch((error) => {
                showError(error.message);
            });
    }

    const onRejectBreakBtnClicked = () => {
        var url = "";
        if (JSON.parse(localStorage.getItem("user")).role == "admin") {
            url = process.env.REACT_APP_API_URL + "attendance/reject-break/" + opened_break?.id;
        }
        else if (JSON.parse(localStorage.getItem("user")).role == "manager") {
            url = process.env.REACT_APP_API_URL + "manager/attendance/reject-break/" + opened_break?.id;
        }
        else if (JSON.parse(localStorage.getItem("user"))?.department?.name == "HR Department") {
            url = process.env.REACT_APP_API_URL + "hr/attendance/reject-break/" + opened_break?.id;
        }

        axios
            .get(url, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    if (response.data.status == 200) {
                        var prev_attendance = attendance;
                        prev_attendance.map((att) => {
                            if (att.date == response.data.result.attendance.date) {
                                att.attendance.working_hours = response.data.result.attendance.working_hours;
                            }
                        });
                        setAttendance(prev_attendance);
                        var prev_breaks = breaks;
                        prev_breaks.map((brk) => {
                            if (brk.id == opened_break.id) {
                                brk.is_approved = response.data.result.break.is_approved;
                                brk.is_rejected = response.data.result.break.is_rejected;
                            }
                        });
                        setBreaks(prev_breaks);
                        setBreakReasonModalShow(false);
                        showSuccess(response.data.message);
                    }
                    else {
                        setBreakReasonModalShow(false);
                        showError(response.data.message);
                    }
                }
            })
            .catch((error) => {
                showError(error.message);
            });
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="users">
            <MyVerticallyCenteredModal
                show={breaksModalShow}
                onHide={() => setBreaksModalShow(false)}
            />
            <Modal
                show={shift_reason_modal_show}
                onHide={() => {
                    setShiftReason("");
                    setShiftReasonModalShow(false);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="users"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Shift Reason
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <label>Reason: </label>
                        </div>
                        <div className="col-lg-12">
                            {
                                opened_attendance?.attendance?.reason ?
                                    <textarea
                                        name="shift_reason" value={shift_reason}
                                        onChange={(e) => onShiftInputFieldChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..."
                                        disabled
                                    ></textarea>
                                    :
                                    <textarea
                                        name="shift_reason" value={shift_reason}
                                        onChange={(e) => onShiftInputFieldChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..."
                                    ></textarea>
                            }
                            <p className="error">{shift_reason_error}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        opened_attendance?.attendance?.reason ?
                            <></>
                            :
                            <Button onClick={onSubmitShiftReasonClicked}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>
            {/* LEAVE REASON MODAL */}
            <Modal
                show={leave_reason_modal_show}
                onHide={() => {
                    setLeaveReason("");
                    setLeaveReasonModalShow(false);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="users"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Leave Reason
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <label>Reason: </label>
                        </div>
                        <div className="col-lg-12">
                            {
                                opened_attendance?.attendance?.leave_reason ?
                                    <textarea
                                        name="leave_reason" value={leave_reason}
                                        onChange={(e) => onLeaveInputReasonChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..."
                                        disabled
                                    ></textarea>
                                    :
                                    <textarea
                                        name="leave_reason" value={leave_reason}
                                        onChange={(e) => onLeaveInputReasonChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..."
                                    ></textarea>
                            }
                            <p className="error">{leave_reason_error}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        opened_attendance?.attendance?.leave_reason ?
                            <></>
                            :
                            <Button onClick={onSubmitLeaveReasonClicked}>Submit</Button>
                    }
                    {
                        JSON.parse(localStorage.getItem("user")).role == "admin"
                            ||
                            (JSON.parse(localStorage.getItem("user")).role == "manager" &&
                                JSON.parse(localStorage.getItem("user")).id !== opened_break?.u_id)
                            ||
                            (JSON.parse(localStorage.getItem("user")).department.name == "HR Department" &&
                                JSON.parse(localStorage.getItem("user")).id !== opened_break?.u_id) ?
                            opened_attendance?.attendance?.leave_reason ?
                                opened_attendance?.attendance?.leave_approved_by == 1 || opened_attendance?.attendance?.leave_rejected_by == 1 ?
                                    <>
                                        <Button disabled={true}>Approve</Button>
                                        <Button disabled={true} className="btn-danger">Reject</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={onApproveBreakBtnClicked}>Approve</Button>
                                        <Button onClick={onRejectBreakBtnClicked} className="btn-danger">Reject</Button>
                                    </>
                                :
                                <></>
                            :
                            <></>
                    }
                </Modal.Footer>
            </Modal>
            {/* <BreakReason
                show={break_reason_modal_show}
                onHide={() => setBreakReasonModalShow(false)}
            /> */}
            <Modal
                show={break_reason_modal_show}
                onHide={() => setBreakReasonModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Break Reason
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 row">
                            <div className="col-lg-6">
                                <label><b>Shift Date:</b></label>
                            </div>
                            <div className="col-lg-6">
                                <label>{opened_break?.date}</label>
                            </div>
                        </div>
                        <div className="col-lg-12 row">
                            <div className="col-lg-6">
                                <label><b>Start At:</b></label>
                            </div>
                            <div className="col-lg-6">
                                <label>{opened_break?.start_at?.split(".")[0]}</label>
                            </div>
                        </div>
                        <div className="col-lg-12 row">
                            <div className="col-lg-6">
                                <label><b>End At:</b></label>
                            </div>
                            <div className="col-lg-6">
                                <label>{opened_break?.end_at?.split(".")[0]}</label>
                            </div>
                        </div>
                        <div className="col-lg-12 row">
                            <div className="col-lg-6">
                                <label><b>Total Break:</b></label>
                            </div>
                            <div className="col-lg-6">
                                <label>{moment.utc(moment(opened_break?.end_at, "HH:mm:ss").diff(moment(opened_break?.start_at, "HH:mm:ss"))).format("HH:mm:ss")}</label>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="col-lg-12">
                            <label><b>Reason: </b></label>
                        </div>
                        <div className="col-lg-12">
                            {
                                opened_break?.reason ?
                                    <textarea name="break_reason"
                                        value={break_reason} onChange={(e) => onBreakInputFieldChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..." disabled></textarea>
                                    :
                                    <textarea name="break_reason"
                                        value={break_reason} onChange={(e) => onBreakInputFieldChanged(e)}
                                        className="form-control reason" placeholder="Enter reason here..."></textarea>
                            }
                            <p className="error">{break_reason_error}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        opened_break?.reason ?
                            <></>
                            :
                            <>
                                <Button onClick={onSubmitBreakReasonClicked}>Submit Only</Button>
                                <Button onClick={onSubmitAndRequestForApprovalBreakReasonClicked}>Submit & Request for approval</Button>
                            </>
                    }
                    {
                        JSON.parse(localStorage.getItem("user")).role == "admin"
                            ||
                            (JSON.parse(localStorage.getItem("user")).role == "manager" &&
                                JSON.parse(localStorage.getItem("user")).id !== opened_break?.u_id)
                            ||
                            (JSON.parse(localStorage.getItem("user")).department.name == "HR Department" &&
                                JSON.parse(localStorage.getItem("user")).id !== opened_break?.u_id) ?
                            opened_break?.request_for_approval ?
                                opened_break?.is_approved == 1 || opened_break?.is_rejected == 1 ?
                                    <>
                                        <Button disabled={true}>Approve</Button>
                                        <Button disabled={true} className="btn-danger">Reject</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={onApproveBreakBtnClicked}>Approve</Button>
                                        <Button onClick={onRejectBreakBtnClicked} className="btn-danger">Reject</Button>
                                    </>
                                :
                                <></>
                            :
                            <></>
                    }
                </Modal.Footer>
            </Modal>

            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ marginTop: "20px" }}>
                <div className="px-5 py-4 border-b border-gray-100">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p style={{ margin: "0px", fontWeight: "bold" }}>NAME: <span style={{ fontWeight: "normal" }}>{user?.name}&nbsp;<span style={{ textTransform: "uppercase" }}>({user?.timezone})</span></span></p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p style={{ margin: "0px", fontWeight: "bold" }}>EMAIL: <span style={{ fontWeight: "normal" }}>{user?.email}</span></p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <p style={{ margin: "0px", fontWeight: "bold" }}>COMPANY: <span style={{ fontWeight: "normal" }}>{user?.company?.name}</span></p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <p style={{ margin: "0px", fontWeight: "bold" }}>DEPARTMENT: <span style={{ fontWeight: "normal" }}>{user?.department?.name}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ marginTop: "20px" }}>
                <div className="px-5 py-4 border-b border-gray-100">
                    <div className="text-center">
                        <select name="month" className="select-month" value={month} onChange={(e) => onInputFieldChanged(e)}>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select name="year" value={year} onChange={(e) => onInputFieldChanged(e)}>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                        <input type="button" value="SEARCH" className="search-attendance-btn" onClick={onSearchBtnClicked} />
                    </div>
                    <br />
                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Sr No.</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Date</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Day</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Time in</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Time out</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Breaks</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Working Hours&nbsp;(hh:min:sec)</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Status</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Action</div>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm divide-y divide-gray-100">
                                {
                                    loading ?
                                        <tr>
                                            <td className="loader-div text-center" colSpan="9">
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                />
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                />
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                />
                                            </td>
                                        </tr>
                                        :
                                        attendance.map((att, i) => {
                                            return (
                                                <tr key={att.id} style={{
                                                    backgroundColor: weekday[new Date(dateFormat(att.date)).getDay()] == "Sunday" || weekday[new Date(dateFormat(att.date)).getDay()] == "Saturday" ?
                                                        "lightblue"
                                                        :
                                                        // att?.attendance?.start_at ?
                                                        "white"
                                                    // :
                                                    // "red"
                                                }}>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="font-medium text-gray-800">{i + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-gray-800">{att.date}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-gray-800">{weekday[new Date(dateFormat(att.date)).getDay()]}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-gray-800">{att?.attendance?.start_at.split(".")[0]}</div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-gray-800">
                                                            {
                                                                att?.attendance?.end_at != "" ?
                                                                    att?.attendance?.end_at?.split(".")[0]
                                                                    :
                                                                    att?.attendance?.last_updated_end_at?.split(".")[0]
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {
                                                            att?.attendance?.start_at && att?.attendance?.start_at != "00:00:00" ?
                                                                <div className="font-medium text-gray-800 text-center view-breaks" onClick={() => onViewBreakClicked(att)}>View Breaks</div>
                                                                :
                                                                <div className="font-medium text-gray-800 text-center">None</div>
                                                        }
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {
                                                            att?.attendance?.start_at && att?.attendance?.start_at != "00:00:00" ?
                                                                <div className="font-medium text-gray-800 text-center">{att?.attendance?.working_hours}</div>
                                                                :
                                                                <div className="font-medium text-gray-800 text-center">00:00:00</div>
                                                        }
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        {
                                                            att?.attendance?.start_at && att?.attendance?.start_at != "00:00:00" ?
                                                                <div className="font-medium text-green-800">Present</div>
                                                                :
                                                                new Date(dateFormat(att.date)).getDay() == 6 || new Date(dateFormat(att.date)).getDay() == 0 ?
                                                                    <div className="font-medium text-blue-800">HOLIDAY</div>
                                                                    :
                                                                    <div className="font-medium text-red-800">Absent</div>
                                                        }
                                                    </td>
                                                    <td className="p-2 whitespace-nowrap">
                                                        <div className="font-medium text-gray-800" style={{ display: "flex" }}>
                                                            {
                                                                new Date(dateFormat(att.date)).getDay() == 6 || new Date(dateFormat(att.date)).getDay() == 0 ?
                                                                    <div className="font-medium text-blue-800">HOLIDAY</div>
                                                                    :
                                                                    att?.attendance?.reason ?
                                                                        <span style={{ cursor: "pointer" }} onClick={() => onShiftAddReasonClicked(att)}>
                                                                            <BiCommentDetail style={{ fontSize: "25px", marginTop: "5px" }} />
                                                                        </span>
                                                                        :
                                                                        JSON.parse(localStorage.getItem("user")).role == "employee" || JSON.parse(localStorage.getItem("user")).role == "manager" ?
                                                                            JSON.parse(localStorage.getItem("user")).id == att?.attendance?.u_id ?
                                                                                <>
                                                                                    <span style={{ cursor: "pointer", marginRight: "5px", marginTop: "5px" }} onClick={() => onShiftAddReasonClicked(att)}>
                                                                                        <BiCommentAdd title="Add Reason" style={{ fontSize: "25px" }} />
                                                                                    </span>
                                                                                </>
                                                                                :
                                                                                new Date(dateFormat(att.date)).getDay() == 6 || new Date(dateFormat(att.date)).getDay() == 0 ?
                                                                                    <></>
                                                                                    :
                                                                                    <span style={{ cursor: "pointer", marginRight: "5px", marginTop: "5px" }} onClick={() => onShiftAddReasonClicked(att)}>
                                                                                        <BiCommentAdd title="Add Reason" style={{ fontSize: "25px" }} />
                                                                                    </span>
                                                                            :
                                                                            <></>
                                                            }
                                                            {/* {
                                                                att?.attendance?.leave_reason ?
                                                                    <span title="Check leave reason" style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => onApplyLeaveIconClicked(att)}>
                                                                        <img src={Leave} style={{ width: "35px", height: "35px" }} />
                                                                    </span>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                JSON.parse(localStorage.getItem("user")).role != "admin" ?
                                                                    JSON.parse(localStorage.getItem("user")).id == att?.attendance?.u_id ?
                                                                        <span title="Apply for leave" style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => onApplyLeaveIconClicked(att)}>
                                                                            <img src={Leave} style={{ width: "35px", height: "35px" }} />
                                                                        </span>
                                                                        :
                                                                        <></>
                                                                    :
                                                                    <></>
                                                            } */}
                                                            {
                                                                JSON.parse(localStorage.getItem("user")).role == "admin" ?
                                                                    att?.attendance?.start_at ?
                                                                        <span style={{ cursor: "pointer", display: "flex" }} onClick={() => props.props.history.push("/user/screenshots/" + att?.attendance?.u_id + "/" + att?.attendance?.date)}>
                                                                            {/* <span style={{ marginLeft: "5px", marginRight: "5px" }}>|</span> */}
                                                                            <BiCamera style={{ fontSize: "25px", marginTop: "5px", marginLeft: "2px" }} />
                                                                        </span>
                                                                        :
                                                                        <></>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <table className="table-auto w-full mx-auto" style={{ maxWidth: "500px" }}>
                        <tbody className="text-xs font-semibold uppercase">
                            <tr>
                                <th className="p-2 whitespace-nowrap bg-gray-50">
                                    <div className="font-semibold text-left">No Of Days of low working hours</div>
                                </th>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="font-medium text-gray-800"><b>{low_working_hours_days}</b></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="p-2 whitespace-nowrap bg-gray-50">
                                    <div className="font-semibold text-left">No Of Absents</div>
                                </th>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="font-medium text-gray-800"><b>{absents}</b></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default UserAttendance;
