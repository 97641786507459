import React, { useEffect, useState } from 'react';
import { BiCommentAdd, BiCommentDetail, BiCamera } from "react-icons/bi";
import moment from 'moment';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import "../../css/users.scss";

function Notifications(props) {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + "notifications/" + notifications.length, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                setNotifications(response.data.result);
            })
            .catch((error) => {
                showError(error.message);
            });
    }, []);

    const seeMoreClicked = () => {
        axios
            .get(process.env.REACT_APP_API_URL + "notifications/" + notifications.length, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                setNotifications(notifications.concat(response.data.result));
            })
            .catch((error) => {
                showError(error.message);
            });
    }

    const onNotificationDropdownClicked = (notification) => {
        if (notification.type == "request_break_approval") {
          localStorage.setItem("opened_break", JSON.parse(notification.params).break_id);
          props.props.history.push({
            pathname: "/user/history/" + notification.from,
            state: {
              opened_break: JSON.parse(notification.params).break_id
            }
          });
        }
      }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="users">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ marginTop: "20px" }}>
                <div className="px-5 py-4 border-b border-gray-100">
                    {/* Table */}
                    <div className="row">
                        {
                            notifications.map((notification) => {
                                return (
                                    <div className="col-lg-12" style={{ padding: "10px", cursor: "pointer", position: "relative" }} 
                                        onClick={() => onNotificationDropdownClicked(notification)}>
                                        {
                                            notification.seen != 1 ?
                                                <span style={{ position: "absolute", left: 0, backgroundColor: "red", height: "10px", width: "10px", borderRadius: "50%", padding: "1px", color: "red", fontSize: "8px" }}>cd</span>
                                                :
                                                <></>
                                        }
                                        <p>{notification.notification}</p>
                                        <p>{notification.created_at.split("T")[0]}</p>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p className="text-right" style={{ cursor: "pointer" }} onClick={seeMoreClicked}>See More</p>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Notifications;
