import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../../css/departments.scss";
import { BsTrashFill } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

function Departments() {
    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
        axios
            .get(process.env.REACT_APP_API_URL + "departments", {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    setDepartments(response.data.result);
                    setLoading(false);
                }
            })
            .catch((error) => {
                showError(error.message);
                setLoading(false);
            });
    }, []);

    const onTrashIconClicked = (department) => {
        setLoading(true);
        axios
            .get(process.env.REACT_APP_API_URL + "department/delete/" + department.id, {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    if (response.data.status == 200) {
                        showSuccess(response.data.message);
                        setDepartments(departments.filter(c => c.id != department.id))
                    }
                    else {
                        showError(response.data.message);
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                showError(error.message);
                setLoading(false);
            });
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 departments">
            <header className="px-5 py-4 border-b border-gray-100" style={{ display: "flex" }}>
                <h2 className="font-semibold text-gray-800">Departments</h2>
                <Link className="new-department-btn" to="/new-department">New Department</Link>
            </header>
            <div className="px-5 py-4 border-b border-gray-100">

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                        {/* Table header */}
                        <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">ID</div>
                                </th>
                                {/* <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Name</div>
                                </th> */}
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Name</div>
                                </th>
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="text-sm divide-y divide-gray-100">
                            {
                                loading ?
                                    <tr>
                                        <td className="loader-div text-center" colSpan="3">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                            />
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                            />
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                            />
                                        </td>
                                    </tr>
                                    :
                                    departments.map(department => {
                                        return (
                                            <tr key={department.id}>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-gray-800">{department.id}</div>
                                                    </div>
                                                </td>
                                                {/* <td className="p-2 whitespace-nowrap">
                                                <div className="font-medium text-gray-800">{department.name}</div>
                                            </td> */}
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{department.name}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="text-left font-medium text-gray-500">
                                                        <BsTrashFill className="trash-icon" onClick={() => onTrashIconClicked(department)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </table>

                </div>

            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Departments;
