import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../css/style.scss';
import Logo from "../images/logo.png";

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split('/')[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [employees_dropdown_is_visible, setEmployeesDropdownIsVisible] = useState(false);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="lg:w-64">
      {/* Sidebar backdrop (mobile only) */}
      <div className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >

        {/* Sidebar header */}
        <div className="flex justify-between mb-0 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink className="block" to={"/user/history/"+ JSON.parse(localStorage.getItem("user")).id}>
            <img src={Logo} style={{ height: "50px", borderRadius: "50%" }} />
          </NavLink>
        </div>

        {/* Links */}
        <div>
          {/* <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">Pages</h3> */}
          <ul className="mt-3">
            {/* Dashboard */}
            {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'dashboard' && 'bg-gray-900'}`}>
              <NavLink exact to="/dashboard" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'dashboard' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-400 ${page === 'dashboard' && 'text-indigo-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                    <path className={`fill-current text-gray-600 ${page === 'dashboard' && 'text-indigo-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                    <path className={`fill-current text-gray-400 ${page === 'dashboard' && 'text-indigo-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                  </svg>
                  <span className="text-sm font-medium">Dashboard</span>
                </div>
              </NavLink>
            </li> */}
            {
              JSON.parse(localStorage.getItem("user")).role == "admin" ?
                <>
                  {/* Companies */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'companies' && 'bg-gray-900'}`}>
                    <NavLink exact to="/companies" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'companies' && 'hover:text-gray-200'}`}>
                      <div className="flex flex-grow">
                        <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-400 ${page === 'companies' && 'text-indigo-300'}`} d="M7 0l6 7H8v10H6V7H1z" />
                          <path className={`fill-current text-gray-600 ${page === 'companies' && 'text-indigo-500'}`} d="M18 7v10h5l-6 7-6-7h5V7z" />
                        </svg>
                        <span className="text-sm font-medium">Companies</span>
                      </div>
                    </NavLink>
                  </li>
                  {/* Departments */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'departments' && 'bg-gray-900'}`}>
                    <NavLink exact to="/departments" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'departments' && 'hover:text-gray-200'}`}>
                      <div className="flex flex-grow">
                        <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-400 ${page === 'departments' && 'text-indigo-300'}`} d="M7 0l6 7H8v10H6V7H1z" />
                          <path className={`fill-current text-gray-600 ${page === 'departments' && 'text-indigo-500'}`} d="M18 7v10h5l-6 7-6-7h5V7z" />
                        </svg>
                        <span className="text-sm font-medium">Departments</span>
                      </div>
                    </NavLink>
                  </li>
                </>
                :
                <></>
            }
            {
              JSON.parse(localStorage.getItem("user")).role == "admin" || JSON.parse(localStorage.getItem("user")).role == "manager" || JSON.parse(localStorage.getItem("user")).department_name == "HR Department" ?
                <>
                  {/* Users */}
                  <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0`} style={{ cursor: "pointer" }}>
                    <div exact
                      to={
                        JSON.parse(localStorage.getItem("user")).role == "admin" ?
                          "/users"
                          :
                          JSON.parse(localStorage.getItem("user")).department_name == "HR Department" ?
                            "/hr/users"
                            :
                            "/manager/users"
                      }
                      onClick={() => setEmployeesDropdownIsVisible(!employees_dropdown_is_visible)}
                      className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'users' && 'hover:text-gray-200'}`}>
                      <div className="flex flex-grow">
                        <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                          <path className={`fill-current text-gray-600 ${page.startsWith('team-') && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                          <path className={`fill-current text-gray-400 ${page.startsWith('team-') && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                        </svg>
                        <span className="text-sm font-medium">Employees</span>
                      </div>
                    </div>
                    {
                      employees_dropdown_is_visible ?
                        <>
                          <NavLink
                            to={
                              JSON.parse(localStorage.getItem("user")).role == "admin" ?
                                "/users/active?company=all&department=all"
                                :
                                JSON.parse(localStorage.getItem("user")).department_name == "HR Department" ?
                                  "/hr/users/active?company=all&department=all"
                                  :
                                  "/manager/users/active?company=all&department=all"
                            }
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            className={`block text-gray-200 :text-white transition duration-150`}>
                            <div className="flex flex-grow">
                              <span style={{ width: "35px", textAlign: "center" }}>&gt;</span>
                              <span className="text-sm font-medium" style={{ marginTop: "2px" }}>Active Employees</span>
                            </div>
                          </NavLink>
                          <NavLink
                            to={
                              JSON.parse(localStorage.getItem("user")).role == "admin" ?
                                "/users/inactive?company=all&department=all"
                                :
                                JSON.parse(localStorage.getItem("user")).department_name == "HR Department" ?
                                  "/hr/users/inactive?company=all&department=all"
                                  :
                                  "/manager/users/inactive?company=all&department=all"
                            }
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            className={`block text-gray-200 hover:text-white transition duration-150`}>
                            <div className="flex flex-grow">
                              <span style={{ width: "35px", textAlign: "center" }}>&gt;</span>
                              <span className="text-sm font-medium" style={{ marginTop: "2px" }}>Inactive Employees</span>
                            </div>
                          </NavLink>
                        </>
                        :
                        <></>
                    }
                  </li>
                </>
                :
                <></>
            }
            {
              JSON.parse(localStorage.getItem("user")).role == "admin" || JSON.parse(localStorage.getItem("user")).department?.name == "HR Department" ?
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'users-attendance' && 'bg-gray-900'}`}>
                  <NavLink exact
                    to="/users-attendance"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'users-attendance' && 'hover:text-gray-200'}`}>
                    <div className="flex flex-grow">
                      <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                        <path className={`fill-current text-gray-600 ${page.startsWith('team-') && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                        <path className={`fill-current text-gray-400 ${page.startsWith('team-') && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                      </svg>
                      <span className="text-sm font-medium">Today's Attendance</span>
                    </div>
                  </NavLink>
                </li>
                :
                <></>
            }
            {
              JSON.parse(localStorage.getItem("user")).role == "employee" || JSON.parse(localStorage.getItem("user")).role == "manager" ?
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === 'user/history' && 'bg-gray-900'}`}>
                  <NavLink exact
                    to={`/user/history/` + JSON.parse(localStorage.getItem("user")).id}
                    className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'user/history' && 'hover:text-gray-200'}`}>
                    <div className="flex flex-grow">
                      <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                        <path className={`fill-current text-gray-600 ${page.startsWith('team-') && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                        <path className={`fill-current text-gray-400 ${page.startsWith('team-') && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                      </svg>
                      <span className="text-sm font-medium">My Attendance</span>
                    </div>
                  </NavLink>
                </li>
                :
                <></>
            }
          </ul>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;