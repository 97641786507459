import React, { useEffect, useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

function ChangePassword(props) {
  const [current_password, setCurrentPassword] = useState("");
  const [current_password_error, setCurrentPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [password_error, setPasswordError] = useState("");
  const [retype_password, setRetypePassword] = useState("");
  const [retype_password_error, setRetypePasswordError] = useState("");

  useEffect(() => {
    document.title = "Change Password";
  });


  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onInputFieldChanged = (e) => {
    if (e.target.name == "current_password") {
      setCurrentPassword(e.target.value);
    }
    else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
    else if (e.target.name == "retype_password") {
      setRetypePassword(e.target.value);
    }
  }

  const handleValidation = () => {
    var formIsValid = true;

    if (!current_password) {
      setCurrentPasswordError("*Current password is required.");
      formIsValid = false;
    }
    else {
      setCurrentPasswordError("");
    }

    if (!password) {
      setPasswordError("*Password is required.");
      formIsValid = false;
    }
    else {
      setPasswordError("");
    }

    if (!retype_password) {
      setRetypePasswordError("*Retype password is required.");
      formIsValid = false;
    }
    else {
      setRetypePasswordError("");
    }

    if (password && retype_password) {
      if (password != retype_password) {
        setRetypePasswordError("*Password and retype password must match.");
        formIsValid = false;
      }
      else {
        setRetypePasswordError("");
      }
    }

    return formIsValid;
  }

  const onSubmitBtnClicked = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      axios
        .post(process.env.REACT_APP_API_URL + "user/change-password", {
          current_password: current_password,
          password: password,
          retype_password: retype_password
        }, {
          headers: {
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
          }
        })
        .then((response) => {
          if (response.data && response.data.status == 200) {
            showSuccess(response.data.message);
          }
          else {
            showError(response.data.message);
          }
        })
        .catch((error) => {
          showError(error.message);
        });
    }
  }

  const showSuccess = (message) => toast.success(message);
  const showError = (message) => toast.error(message);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} props={props} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 new-company">
              <form onSubmit={e => { onSubmitBtnClicked(e) }}>
                <label>Current Password</label>
                <input name="current_password" type="password" value={current_password} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter your current password" className="form-control" />
                <p className="error">{current_password_error}</p>
                <br />
                <label>New Password</label>
                <input name="password" type="password" value={password} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter your new password" className="form-control" />
                <p className="error">{password_error}</p>
                <br />
                <label>Retype Password</label>
                <input name="retype_password" type="password" value={retype_password} onChange={(e) => onInputFieldChanged(e)} placeholder="Retype your new password" className="form-control" />
                <p className="error">{retype_password_error}</p>
                <br />
                <input type="submit" className="btn btn-block submit-btn" />
              </form>
            </div>
          </div>
        </main>

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ChangePassword;