import React, { useEffect, useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import "../../css/new-company.scss";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

function EditUser(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [name, setName] = useState("");
  const [name_error, setNameError] = useState("");
  // const [email, setEmail] = useState("");
  // const [email_error, setEmailError] = useState("");
  const [time_zone, setTimezone] = useState("");
  const [time_zone_error, setTimezoneError] = useState("");
  const [status, setStatus] = useState();
  const [status_error, setStatusError] = useState("");

  useEffect(() => {
    document.title = "Edit User";

    axios
      .get(process.env.REACT_APP_API_URL + "user-mg/" + props.match.params.id, {
        headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
        }
      })
      .then((response) => {
        if (response.data && response.data.status == 200) {
          setName(response.data.result.name);
          // setEmail(response.data.result.email);
          setTimezone(response.data.result.timezone);
          setStatus(response.data.result.status);
        }
      });
  }, []);

  const onInputFieldChanged = (e) => {
    if (e.target.name == "full_name") {
      setName(e.target.value);
    }
    // else if (e.target.name == "email") {
    //   setEmail(e.target.value);
    // }
    else if (e.target.name == "time_zone") {
      setTimezone(e.target.value);
    }
    else if (e.target.name == "status") {
      setStatus(e.target.value);
    }
  }

  const handleValidation = () => {
    var isValid = true;

    if (!name) {
      setNameError("*Name is required.");
      isValid = false;
    }
    else {
      setNameError("");
    }

    // if (!email) {
    //   setEmailError("*Email is required.");
    //   isValid = false;
    // }
    // else {
    //   setEmailError("");
    // }

    if (!time_zone) {
      setTimezoneError("*Timezone is required.");
      isValid = false;
    }
    else {
      setTimezoneError("");
    }

    if (status == null) {
      setStatusError("*Status is required.");
      isValid = false;
    }
    else {
      setStatusError("");
    }

    return isValid;
  };

  const onSubmitBtnClicked = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      axios
        .post(process.env.REACT_APP_API_URL + "user/edit-mg/" + props.match.params.id, {
          name: name,
          time_zone: time_zone,
          cnic: "",
          contact: "",
          status: status
        }, {
          headers: {
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
          }
        })
        .then((response) => {
          if (response.data) {
            if (response.data.status == 200) {
              localStorage.setItem("success_message", response.data.message);
              props.history.goBack();
            }
            else {
              showError(response.data.message);
            }
          }
        })
        .catch((error) => {
          showError(error.message);
        })
    }
  }

  const showError = (message) => toast.error(message);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} props={props} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 new-company">
              <form onSubmit={e => {onSubmitBtnClicked(e)}}>
                <label>Name</label>
                <input name="full_name" type="text" value={name} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter full name" className="form-control" />
                <p className="error">{name_error}</p>
                <br />
                {/* <label>Email</label>
                <input name="email" type="text" value={email} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter email" className="form-control" />
                <p className="error">{email_error}</p>
                <br /> */}
                <label>Time Zone</label>
                <select name="time_zone" value={time_zone} onChange={(e) => onInputFieldChanged(e)} className="form-control">
                  <option value="">---Select Time Zone---</option>
                  <option value="pak">Pakistan</option>
                  <option value="usa">United States(US)</option>
                  <option value="uk">United Kingdom(UK)</option>
                </select>
                <p className="error">{time_zone_error}</p>
                <br />
                <label>Status</label>
                <select name="status" value={status} onChange={(e) => onInputFieldChanged(e)} className="form-control">
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <p className="error">{status_error}</p>
                <br />
                <input type="submit" className="btn btn-block submit-btn" />
              </form>
            </div>
          </div>
        </main>

      </div>
    </div>
  );
}

export default EditUser;