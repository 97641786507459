import React, { useEffect, useState } from 'react';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

function ShiftScreenshots(props) {
    const [loading, setLoading] = useState(true);
    const [screenshots, setScreenshots] = useState([]);

    useEffect(() => {
        if (
            JSON.parse(localStorage.getItem("user")).role == "admin" ||
            JSON.parse(localStorage.getItem("user")).department.name == "HR Department"
        ) {
            axios
                .get(process.env.REACT_APP_API_URL + "shift/screenshots/" + props.props.match.params.user + "/" + props.props.match.params.date, {
                    headers: {
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                    }
                })
                .then((response) => {
                    if (response.data) {
                        let screenshots1 = [];

                        response.data.result.forEach(element => {
                            screenshots1 = screenshots1.concat({
                                original: element,
                                thumbnail: element
                            });
                        });

                        setScreenshots(screenshots1);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    showError(error.message);
                    setLoading(false);
                });
        }
        else {
            showError("Access Denied!");
        }
    }, []);

    const showError = (message) => toast.error(message);

    return (
        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ padding: "15px" }}>
            <ImageGallery items={screenshots} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )

}

export default ShiftScreenshots;