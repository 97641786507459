import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/Login.scss";
import { Modal, Button, Spinner } from 'react-bootstrap';
// require('dotenv').config();
const axios = require('axios');

function Login(props) {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [forgot_email, setForgotEmail] = useState("");
    const [forgot_email_error, setForgotEmailError] = useState("");
    const [change_password_modal_is_show, setChangePasswordModalIsShow] = useState(false);
    const [new_password, setNewPassword] = useState("");
    const [re_new_password, setReNewPassword] = useState("");
    const [new_password_error, setNewPasswordError] = useState("");
    const [re_new_password_error, setReNewPasswordError] = useState("");

    const [loginBtnPressed, setLoginBtnPressed] = useState(false);

    useEffect(() => {
        document.title = "Login";
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
        if (props.match.params.token && props.match.params.email) {
            setChangePasswordModalIsShow(true);
        }
    }, []);

    function onInputFieldChanged(e) {
        if (e.target.name == "email") {
            setEmail(e.target.value);
        }
        else if (e.target.name == "password") {
            setPassword(e.target.value);
        }
    }

    function handleValidation() {
        var formIsValid = true;

        if (!email) {
            setEmailError("*Email is required.");
            formIsValid = false;
        }
        else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError("*Password is required.");
            formIsValid = false;
        }
        else {
            setPasswordError("");
        }

        return formIsValid;
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (handleValidation()) {
            setLoginBtnPressed(true);
            axios
                .post(process.env.REACT_APP_API_URL + "user/login", {
                    email: email,
                    password: password
                })
                .then((response) => {
                    if (response.data.status == 200) {
                        if (response.data.result.status == 1) {
                            localStorage.setItem("user", JSON.stringify(response.data.result));
                            localStorage.setItem("success_message", "User logged in successfully.");
                            if (JSON.parse(localStorage.getItem("user")).role == "admin") {
                                props.history.push("/users/active?company=all&department=all");
                            }
                            else {
                                if (JSON.parse(localStorage.getItem("user")).department_name == "HR Department") {
                                    props.history.push("/user/history/" + JSON.parse(localStorage.getItem("user")).id);
                                }
                                else if (JSON.parse(localStorage.getItem("user")).role == "manager") {
                                    props.history.push("/user/history/" + JSON.parse(localStorage.getItem("user")).id);
                                }
                                else if (JSON.parse(localStorage.getItem("user")).role == "employee") {
                                    props.history.push("/user/history/" + JSON.parse(localStorage.getItem("user")).id);
                                }
                            }
                        }
                        else {
                            showError("Your account is deactivated. Contact to admin for more details.");
                            setLoginBtnPressed(false);
                        }
                    }
                    else {
                        showError(response.data.message);
                        setLoginBtnPressed(false);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                    setLoginBtnPressed(false);
                });
        }
    }

    function forgotPasswordLabelClicked() {
        setForgotPasswordModal(true);
    }

    function onForgotModalInputFieldChanged(e) {
        if (e.target.name == "forgot_email") {
            setForgotEmail(e.target.value);
        }
    }

    function handleForgotPasswordValidation() {
        var formIsValid = true;

        if (!forgot_email) {
            setForgotEmailError("*Email is required.");
            formIsValid = false;
        }
        else {
            setForgotEmailError("");
        }

        return formIsValid;
    }

    function onForgotPasswordBtnClicked() {
        if (handleForgotPasswordValidation()) {
            axios
                .post(process.env.REACT_APP_API_PUBLIC_URL + "forgot-password", {
                    email: email,
                    password: password
                })
                .then((response) => {
                    if (response.data.status == 200) {
                        setForgotPasswordModal(false);
                        showSuccess(response.data.message);
                    }
                    else {
                        showError(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error.message);
                });
        }
    }

    function onResetInputFieldChanged(e) {
        if (e.target.name == "new_password") {
            setNewPassword(e.target.value);
        }
        else if (e.target.name == "re_password") {
            setReNewPassword(e.target.value);
        }
    }

    function handleChangePasswordModalClosed() {
        setChangePasswordModalIsShow(false);
    }

    function handleResetPasswordValidation() {
        var formIsValid = true;

        if (!new_password) {
            setNewPasswordError("*New password is required.");
            formIsValid = false;
        }
        else {
            setNewPasswordError("");
        }

        if (!re_new_password) {
            setReNewPasswordError("*Retype password is required.");
            formIsValid = false;
        }
        else {
            if (new_password != re_new_password) {
                setReNewPasswordError("Password and retype password must match.");
                formIsValid = false;
            }
            else {
                setReNewPasswordError("");
            }
        }

        return formIsValid;
    }

    function onResetSubmitBtnClicked() {
        if (handleResetPasswordValidation()) {
            axios
                .post(process.env.REACT_APP_API_PUBLIC_URL + "api/reset-password", {
                    token: props.match.params.token,
                    email: props.match.params.email.replace("%40", "@"),
                    password: new_password,
                    confirm_password: re_new_password
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": "true"
                    }
                })
                .then((response) => {
                    if (response.data && response.data.status == 200) {
                        showSuccess(response.data.message);
                    }
                    else {
                        showError(response.data.message);
                    }
                })
                .catch((error) => {
                    showError(error);
                })
        }
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="login">
            <Modal
                show={forgotPasswordModal}
                onHide={() => {
                    setForgotEmail("");
                    setForgotPasswordModal(false);
                }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="login"
                style={{ marginTop: "0px" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Forgot Password?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <label>Email: </label>
                        </div>
                        <div className="col-lg-12">
                            <input
                                name="forgot_email" value={forgot_email}
                                onChange={(e) => onForgotModalInputFieldChanged(e)}
                                className="form-control" placeholder="Enter your email here..."
                            />
                            <p className="error">{forgot_email_error}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onForgotPasswordBtnClicked} className="loginBtn">Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal id="changePasswordModal" className="login" style={{ marginTop: "0px" }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={change_password_modal_is_show} onHide={handleChangePasswordModalClosed}>
                <Modal.Body>
                    {/* <img src={process.env.PUBLIC_URL + "/img/forgot_password.png"} /> */}
                    <div style={{ padding: 0 }}>
                        <p style={{ margin: "0px", marginTop: "24px", fontSize: "18px", color: "#0b101c" }}>Update your password?</p>
                        <p style={{ margin: "0px", color: "#0b101c", marginTop: 33, fontSize: "14px" }}>Enter New Password</p>
                        <input name="new_password" type="password" className="form-control" onChange={onResetInputFieldChanged} placeholder="**********" value={new_password} />
                        <p style={{ margin: "0px", color: "red" }}>{new_password_error}</p>
                        <p style={{ margin: "0px", color: "#0b101c", marginTop: 33, fontSize: "14px" }}>Enter New Password</p>
                        <input name="re_password" type="password" className="form-control" onChange={onResetInputFieldChanged} placeholder="**********" value={re_new_password} />
                        <p style={{ margin: "0px", color: "red" }}>{re_new_password_error}</p>
                        <div className="text-center">
                            <input type="button" value="CHANGE" style={{ marginTop: "30px", width: "100%", height: "40px" }} onClick={onResetSubmitBtnClicked} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="card mx-auto">
                <h4>Sign In</h4>
                <form onSubmit={e => { handleSubmit(e) }}>
                    <label>Email Address</label>
                    <input name="email" type="email" value={email} placeholder="Enter your email here" onChange={(e) => onInputFieldChanged(e)} />
                    <p className="error">{emailError}</p>
                    <label>Password</label>
                    <input name="password" type="password" value={password} placeholder="Enter your password here" onChange={(e) => onInputFieldChanged(e)} />
                    <p className="error">{passwordError}</p>
                    {/* <p className="text-right forgotPassword" onClick={forgotPasswordLabelClicked}>Forgot Password?</p> */}
                    {
                        loginBtnPressed ?
                            <div className="loginBtn text-center loader-div">
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                />
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                />
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                />
                            </div>
                            :
                            <input className="loginBtn" type="submit" value="Sign In Now!" />
                    }
                </form>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Login;