import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsPencilSquare } from 'react-icons/bs';
import { BsCalendar } from 'react-icons/bs';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "../../css/users.scss";

function Users(props) {
    const [users, setUsers] = useState([]);
    const [filtered_users, setFilteredUsers] = useState([]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
        axios
            .get(process.env.REACT_APP_API_URL + "users-mg/" + props.props.match.params.status, {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    setUsers(response.data.result);
                    setFilteredUsers(response.data.result);
                }
            })
            .catch((error) => {
                showError(error.message);
            });
    }, [props.props.match.params.status]);

    const onNameSearchFieldChanged = (e) => {
        setName(e.target.value);
        setEmail("");
        setFilteredUsers(users.filter(user => (user.name.toLowerCase()).includes(e.target.value.toLowerCase())));
    }

    const onEmailSearchFieldChanged = (e) => {
        setEmail(e.target.value);
        setName("");
        setFilteredUsers(users.filter(user => (user.email.toLowerCase()).includes(e.target.value.toLowerCase())));
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="users">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ padding: "20px" }}>
                <div className="row">
                    <div className="col-lg-6">
                        <input type="text" placeholder="Search by name" value={name} className="form-control" onChange={(e) => onNameSearchFieldChanged(e)} />
                    </div>
                    <div className="col-lg-6">
                        <input type="text" placeholder="Search by email" value={email} className="form-control" onChange={(e) => onEmailSearchFieldChanged(e)} />
                    </div>
                </div>
            </div>
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ marginTop: "20px" }}>
                <header className="px-5 py-4 border-b border-gray-100" style={{ display: "flex" }}>
                    <h2 className="font-semibold text-gray-800" style={{ textTransform: "uppercase" }}>{props.props.match.params.status} Users</h2>
                    {/* <Link className="new-user-btn" to="/manager/new-user">New User</Link> */}
                </header>
                <div className="px-5 py-4 border-b border-gray-100">

                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">ID</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Email</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Company</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Department</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Action</div>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm divide-y divide-gray-100">
                                {
                                    filtered_users.map(user => {
                                        return (
                                            <tr key={user.id}>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-gray-800">{user.id}</div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        {/* <div className="flex items-center">
                                                        <img className="rounded-full" src={customer.image} width="40" height="40" alt={customer.name} />
                                                    </div> */}
                                                        <div className="font-medium text-gray-800 title">{user.name}</div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.email}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.company?.name}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.department?.name}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="text-left font-medium text-gray-500" style={{ display: "flex" }}>
                                                        <Link to={JSON.parse(localStorage.getItem("user")).role == "admin" ? `/edit-user/` + user.id : `/manager/edit-user/` + user.id}>
                                                            <BsPencilSquare className="edit-icon" />
                                                        </Link>
                                                        <Link to={`/user/history/` + user.id}>
                                                            <BsCalendar className="calendar-icon" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Users;
