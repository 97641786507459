import React, { useEffect, useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import "../../css/new-company.scss";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

function EditCompany(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [name, setName] = useState();
  const [name_error, setNameError] = useState();
  const [logo, setLogo] = useState("");
  const [company, setCompany] = useState("");
  const [logo_error, setLogoError] = useState();

  const [submitBtnPressed, setSubmitBtnPressed] = useState(false);

  useEffect(() => {
    document.title = "Edit Company";

    axios
      .get(process.env.REACT_APP_API_URL + "company/" + props.match.params.id, {
        headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
        }
      })
      .then((response) => {
        if (response.data) {
          setName(response.data.result.name);
          setCompany(response.data.result);
        }
      })
      .catch((error) => {
        showError("Something went wrong. Try again.");
      })
  }, []);

  const onInputFieldChanged = (e) => {
    if (e.target.name == "company_name") {
      setName(e.target.value);
    }
    else if (e.target.name == "company_logo") {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      createImage(files[0]);
    }
  }

  const createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setLogo(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  const handleValidation = () => {
    var isValid = true;

    if (!name) {
      setNameError("*Company name is required.");
      isValid = false;
    }

    // if (!logo) {
    //   setLogoError("*Company logo is required.");
    //   isValid = false;
    // }

    return isValid;
  };

  const onSubmitBtnClicked = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setSubmitBtnPressed(true);
      axios
        .post(process.env.REACT_APP_API_URL + "company/edit/" + props.match.params.id, {
          name: name,
          logo: logo
        }, {
          headers: {
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
          }
        })
        .then((response) => {
          if (response.data) {
            if (response.data.status == 200) {
              localStorage.setItem("success_message", response.data.message);
              props.history.goBack();
            }
            else {
              showError(response.data.message);
            }
          }
          setSubmitBtnPressed(false);
        })
        .catch((error) => {
          showError(error.message);
          setSubmitBtnPressed(false);
        })
    }
  }

  const showError = (message) => toast.error(message);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} props={props} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 new-company">
              <form onSubmit={e => { onSubmitBtnClicked(e) }}>
                <label>Name</label>
                <input name="company_name" type="text" value={name} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter company name" className="form-control" />
                <p className="error">{name_error}</p>
                <br />
                <label>Logo</label>
                <img src={process.env.REACT_APP_API_PUBLIC_URL + "storage/companies/" + company.id + "/" + company.logo} />
                <label>If you want to change logo, upload here:</label>
                <input name="company_logo" type="file" onChange={(e) => onInputFieldChanged(e)} className="form-control" />
                <p className="error">{logo_error}</p>
                <br />
                {
                  submitBtnPressed ?
                    <div className="btn btn-block submit-btn text-center loader-div">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                      />
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                      />
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                      />
                    </div>
                    :
                    <input type="submit" className="btn btn-block submit-btn" />
                }
              </form>
            </div>
          </div>
        </main>

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditCompany;