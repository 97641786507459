import React from "react";
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from "react-image-magnifiers";

function Image() {
    return (
        <GlassMagnifier
            imageSrc={process.env.PUBLIC_URL + "pic.jpg"}
            imageAlt="User"
            largeImageSrc={process.env.PUBLIC_URL + "pic.jpg"} // Optional
        />
        // <img src={process.env.PUBLIC_URL + "pic.jpg"} />
    )
}

export default Image;