import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BsCalendar } from 'react-icons/bs';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import queryString from "query-string";
import "../../css/users.scss";

function Users(props) {
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [filtered_users, setFilteredUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [company, setCompany] = useState("all");
    const [department, setDepartment] = useState("all");

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const query = queryString.parse(location.search);

    useEffect(() => {
        if (localStorage.getItem("success_message")) {
            showSuccess(localStorage.getItem("success_message"));
            localStorage.removeItem("success_message");
        }
        axios
            .get(process.env.REACT_APP_API_URL + "users-hr/" + props.props.match.params.status, {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data) {
                    setUsers(response.data.result);
                    if (JSON.stringify(query) != "{}") {
                        setCompany(query.company);
                        setDepartment(query.department);
                        if (query.department && query.company) {
                            if (query.department == "all" && query.company == "all") {
                                setFilteredUsers(response.data.result);
                            }
                            else if (query.department == "all" && query.company != "all") {
                                setFilteredUsers(response.data.result.filter(user => user.company_id == query.company));
                            }
                            else if (query.department != "all" && query.company == "all") {
                                setFilteredUsers(response.data.result.filter(user => user.department_id == query.department));
                            }
                            else {
                                setFilteredUsers(response.data.result.filter(user => user.department_id == query.department && user.company_id == query.company));
                            }
                        }
                        else {
                            if (query.department) {
                                if (query.department == "all") {
                                    setFilteredUsers(response.data.result);
                                }
                                else {
                                    setFilteredUsers(response.data.result.filter(user => user.department_id == query.department));
                                }
                            }
                            else if (query.company) {
                                if (query.company == "all") {
                                    setFilteredUsers(response.data.result);
                                }
                                else {
                                    setFilteredUsers(response.data.result.filter(user => user.company_id == query.company));
                                }
                            }
                        }
                    }
                    else {
                        setFilteredUsers(response.data.result);
                    }
                }
            })
            .catch((error) => {
                showError(error.message);
            });

        axios
            .get(process.env.REACT_APP_API_URL + "hr/companies", {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data && response.data.status == 200) {
                    setCompanies(response.data.result);
                }
            });

        axios
            .get(process.env.REACT_APP_API_URL + "hr/departments", {
                headers: {
                    "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
                }
            })
            .then((response) => {
                if (response.data && response.data.status == 200) {
                    setDepartments(response.data.result);
                }
            });
    }, [props.props.match.params.status, useParams()])

    const onInputFieldChanged = (e) => {
        if (e.target.name == "company") {
            setCompany(e.target.value);
            // setDepartment("");
        }
        else if (e.target.name == "department") {
            setDepartment(e.target.value);
            // setCompany("");
        }
    }

    const searchBtnClicked = () => {
        props.props.history.push("/hr/users/"+props.props.match.params.status+"?company="+company+"&department="+department);
        // if (department && company) {
        //     if (department == "all" && company == "all") {
        //         setFilteredUsers(users);
        //     }
        //     else if (department == "all" && company != "all") {
        //         setFilteredUsers(users.filter(user => user.company_id == company));
        //     }
        //     else if (department != "all" && company == "all") {
        //         setFilteredUsers(users.filter(user => user.department_id == department));
        //     }
        //     else {
        //         setFilteredUsers(users.filter(user => user.department_id == department && user.company_id == company));
        //     }
        // }
        // else {
        //     if (department) {
        //         if (department == "all") {
        //             setFilteredUsers(users);
        //         }
        //         else {
        //             setFilteredUsers(users.filter(user => user.department_id == department));
        //         }
        //     }
        //     else if (company) {
        //         if (company == "all") {
        //             setFilteredUsers(users);
        //         }
        //         else {
        //             setFilteredUsers(users.filter(user => user.company_id == company));
        //         }
        //     }
        // }
    }

    const onNameSearchFieldChanged = (e) => {
        setName(e.target.value);
        setEmail("");
        setFilteredUsers(users.filter(user => (user.name.toLowerCase()).includes(e.target.value.toLowerCase())));
    }

    const onEmailSearchFieldChanged = (e) => {
        setEmail(e.target.value);
        setName("");
        setFilteredUsers(users.filter(user => (user.email.toLowerCase()).includes(e.target.value.toLowerCase())));
    }

    const showError = (message) => toast.error(message);
    const showSuccess = (message) => toast.success(message);

    return (
        <div className="users">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ padding: "20px" }}>
                <div className="row">
                    <div className="col-lg-3">
                        <select name="company" value={company} className="form-control" onChange={(e) => onInputFieldChanged(e)}>
                            <option value="all">---Select Company--</option>
                            {/* <option value="all">All</option> */}
                            {
                                companies.map(company => {
                                    return (
                                        <option value={company.id}>{company.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-3">
                        <select name="department" value={department} className="form-control" onChange={(e) => onInputFieldChanged(e)}>
                            <option value="all">---Select Department--</option>
                            {/* <option value="all">All</option> */}
                            {
                                departments.map(department => {
                                    return (
                                        <option value={department.id}>{department.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-3">
                        <input type="button" className="search-btn" value="Search" onClick={searchBtnClicked} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-6">
                        <input type="text" placeholder="Search by name" value={name} className="form-control" onChange={(e) => onNameSearchFieldChanged(e)} />
                    </div>
                    <div className="col-lg-6">
                        <input type="text" placeholder="Search by email" value={email} className="form-control" onChange={(e) => onEmailSearchFieldChanged(e)} />
                    </div>
                </div>
            </div>
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200" style={{ marginTop: "20px" }}>
                <header className="px-5 py-4 border-b border-gray-100" style={{ display: "flex" }}>
                    <h2 className="font-semibold text-gray-800" style={{ textTransform: "uppercase" }}>{props.props.match.params.status} Employees</h2>
                    {/* <Link className="new-user-btn" to="/manager/new-user">New User</Link> */}
                </header>
                <div className="px-5 py-4 border-b border-gray-100">

                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">ID</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Name</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Email</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Role</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Company</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Department</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Action</div>
                                    </th>
                                </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm divide-y divide-gray-100">
                                {
                                    filtered_users.map(user => {
                                        return (
                                            <tr key={user.id}>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-gray-800">{user.id}</div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        {/* <div className="flex items-center">
                                                        <img className="rounded-full" src={customer.image} width="40" height="40" alt={customer.name} />
                                                    </div> */}
                                                        <div className="font-medium text-gray-800 title">{user.name}</div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.email}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800" style={{ textTransform: "capitalize" }}>{user.role}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.company?.name}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="font-medium text-gray-800">{user.department?.name}</div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="text-left font-medium text-gray-500">
                                                        <Link to={`/user/history/` + user.id}>
                                                            <BsCalendar className="calendar-icon" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Users;
