import React, { useEffect, useState } from 'react';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import "../../css/new-company.scss";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

function NewUser(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [name, setName] = useState("");
  const [name_error, setNameError] = useState("");
  const [password, setPassword] = useState("");
  const [password_error, setPasswordError] = useState("");
  const [time_zone, setTimezone] = useState("");
  const [time_zone_error, setTimezoneError] = useState("");

  useEffect(() => {
    document.title = "New User";
  }, []);

  const onInputFieldChanged = (e) => {
    if (e.target.name == "full_name") {
      setName(e.target.value);
    }
    else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
    else if (e.target.name == "time_zone") {
      setTimezone(e.target.value);
    }
  }

  const handleValidation = () => {
    var isValid = true;

    if (!name) {
      setNameError("*Name is required.");
      isValid = false;
    }
    else {
      setNameError("");
    }

    if (!password) {
      setPasswordError("*Password is required.");
      isValid = false;
    }
    else {
      setPasswordError("");
    }

    if (!time_zone) {
      setTimezoneError("*Timezone is required.");
      isValid = false;
    }
    else {
      setTimezoneError("");
    }

    return isValid;
  };

  const onSubmitBtnClicked = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      axios
        .post(process.env.REACT_APP_API_URL + "user/register-mg", {
          name: name,
          password: password,
          time_zone: time_zone,
          cnic: "",
          contact: ""
        }, {
          headers: {
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("user")).api_token
          }
        })
        .then((response) => {
          if (response.data) {
            if (response.data.status == 200) {
              localStorage.setItem("success_message", response.data.message);
              props.history.goBack();
            }
            else {
              showError(response.data.message);
            }
          }
        })
        .catch((error) => {
          showError(error.message);
        })
    }
  }

  const showError = (message) => toast.error(message);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} props={props} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 new-company">
              <form onSubmit={e => {onSubmitBtnClicked(e)}}>
                <label>Name</label>
                <input name="full_name" type="text" value={name} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter full name" className="form-control" />
                <p className="error">{name_error}</p>
                <br />
                <label>Password</label>
                <input name="password" type="password" value={password} onChange={(e) => onInputFieldChanged(e)} placeholder="Enter password" className="form-control" />
                <p className="error">{password_error}</p>
                <br />
                <label>Time Zone</label>
                <select name="time_zone" value={time_zone} onChange={(e) => onInputFieldChanged(e)} className="form-control">
                  <option value="">---Select Time Zone---</option>
                  <option value="pak">Pakistan</option>
                  <option value="usa">United States(US)</option>
                  <option value="uk">United Kingdom(UK)</option>
                </select>
                <p className="error">{time_zone_error}</p>
                <br />
                <input type="submit" className="btn btn-block submit-btn" />
              </form>
            </div>
          </div>
        </main>

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default NewUser;